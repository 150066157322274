@import '../../node_modules/react-phone-input-2/lib/style.css';

.react-tel-input .form-control {
  border: none;
  width: 100%;
  border-bottom: 1.25px solid #b3b3b3;
  border-radius: 0;
}

.react-tel-input .form-control:active,
.react-tel-input .form-control:focus,
.react-tel-input .form-control:hover {
  border-bottom: 1.25px solid #123dc6;
}

.react-tel-input .flag-dropdown {
  background-color: transparent;
  border: none;
}

.react-tel-input .country-list .search-box {
  width: calc(100% - 8px);
  margin-left: 0;
  border: 1.25px solid #b3b3b3;
}

.react-tel-input .country-list .search-box:active,
.react-tel-input .country-list .search-box:focus,
.react-tel-input .country-list .search-box:hover {
  border: 1.25px solid #37cc82;
}

.react-tel-input .country-list .search-emoji {
  position: absolute;
  right: 14px;
  top: 11px;
  transform: rotateY(180deg);
}
