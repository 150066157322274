.App {
  font-family: sans-serif;
}

.App nav li {
  list-style-type: none;
}

.App {
  height: 100vh;
  background: rgba(245, 245, 220, 0.548);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.this svg {
  position: absolute;
  top: 50%;
  left: 5.5px;
  transform: translateY(-50%);
}

.this svg g.calendar {
  fill: white;
}

.this.active svg g.calendar {
  fill: #37cc82;
}

.this.active.mentor svg g.calendar {
  fill: #5185e0;
}

input.ant-calendar-picker-input.ant-input {
  border: 1px solid white;
  border-radius: 4px;
  color: white;
  background-color: #37cc82;
  padding: 4px 35.5px;
  height: 40px;
  font-size: 15px;
  line-height: 25px;
  font-weight: bold;
  width: 150px;
}

input.ant-calendar-picker-input.ant-input:disabled {
  opacity: 0.3;
}

.this.mentor input.ant-calendar-picker-input.ant-input {
  background-color: #5185e0;
}

.this.active input.ant-calendar-picker-input.ant-input {
  color: #37cc82;
  border: 1px solid #37cc82;
  background-color: white;
}

.this.active.mentor input.ant-calendar-picker-input.ant-input {
  color: #5185e0;
  border: 1px solid #5185e0;
  background-color: white;
}

input.ant-calendar-picker-input.ant-input::placeholder {
  color: white;
}

.this.ant-calendar-picker {
  display: flex;
  flex-basis: 128px;
  max-width: 150px;
}

.this.active {
  background-color: white;
}

i.anticon.anticon-close-circle.ant-calendar-picker-clear {
  opacity: 1;
}
